

























import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AccordionLoading from '@/components/AccordionLoading/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import ContainerAccordionSimulated from '../../components/ContainerAccordionSimulated/ContainerAccordionSimulated.vue';

import { SimulatedWithChildren as ISimulatedWithChildren, Simulated as ISimulated } from '../../components/interface/IOtherSimulatedAccordion';

import SimulationService from '@/services/Simulation/SimulationService';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    ContainerAccordionSimulated,
    AccordionLoading,
    Footer,
  },
})
export default class PreviousVestibular extends Vue {
  private isLoading = true;
  private simulatedData: ISimulatedWithChildren | null = null;
  private ServiceSimulation = new SimulationService();

  async created() {
    await this.getChildrenSimulated();
    this.setBreadCrumbs();
  }

  mounted() {
    this.$store.commit('savePreviousPageRoute', this.$route.fullPath);
  }

  async getChildrenSimulated() {
    try {
      this.isLoading = true;

      const { id } = this.$route.params;
      const response = await this.ServiceSimulation.getChildrenSimulated(Number(id));

      this.simulatedData = response;

      if (this.simulatedData?.children?.length) {
        this.simulatedData.children = this.orderSimulations(this.simulatedData?.children);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar lista de simulados.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  orderSimulations(listSimulations: Array<ISimulated>) {
    return listSimulations.sort((currentItem, nextItem) => {
      if (currentItem.title < nextItem.title) return -1;
      if (currentItem.title > nextItem.title) return 1;
      return 0;
    });
  }

  setBreadCrumbs() {
    const simulationTitle = this.simulatedData?.title;

    this.$breadcrumb.set([
      { title: 'Simulados', to: '/simulados' },
      { title: simulationTitle || '', to: null },
    ]);
  }
}
